import React, { useState, useEffect } from 'react';
import logo from '../images/logo.webp'; 
import icon from '../images/search.png';
import flight from '../images/flight.png';
import hotel from '../images/hotel.png';
import holiday from '../images/holiday.png';
import cruise from '../images/cruise.png';
import visa from '../images/visa.png';
import contact from '../images/contact.png';
import flag from '../images/flag.jpg';
import Holidays from './holidays';
import { Helmet } from 'react-helmet';
export default function Navbar() {
  const [isScrolledPast, setIsScrolledPast] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHolidaysOpen, setIsHolidaysOpen] = useState(false);
  useEffect(() => {
    
    const handleScroll = () => {
      const slidingImgHeight = 300;
      if (window.scrollY > slidingImgHeight ) {
        setIsScrolledPast(true);
      } else {
        setIsScrolledPast(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
   
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMouseEnter = () => {
    setIsHolidaysOpen(true);
  };

  const handleMouseLeave = () => {
    setIsHolidaysOpen(false);
  };

  return (
    <>
      <Helmet>
        <meta property="og:image" content="/images/logo.webp" />
      </Helmet>
    <nav className={`fixed top-0 left-0 w-full z-50 flex flex-col lg:flex-row items-center justify-between p-3 transition-colors duration-300 bg-[#D8DEE9]`}>
     <div className="flex items-center justify-between w-full lg:w-auto">
        <img className="w-12 lg:w-16 " src={logo} alt="Logo" />
        <a href="/">
        <div className=''><p className="text-3xl  text-blue-900 font-bold satisfy-regular">Wings In Air</p>
        <p className='text-lg caveat ml-1 text-blue-900'>Fly With Freedom, </p>
        <p className='text-lg caveat pl-5 -m-2 text-blue-900 '>Explore With Wings In Air...</p></div>
        </a>
        <button className="lg:hidden text-black focus:outline-none" onClick={toggleMenu}>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"} />
          </svg>
        </button>
      </div>
      <div className={`w-full lg:flex lg:items-center lg:justify-center ${isMenuOpen ? 'block' : 'hidden'} lg:w-auto`}>
        <div className="flex flex-row text-center lg:text-right lg:space-x-0">
        <a link="/#Holiday" className="block lg:inline-block hover:text-blue-500 p-3 text-black text-xs lg:text-base"> <div className='flex flex-col items-center' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}><img src={holiday} className='w-6 lg:w-6 '></img>
         Holidays</div></a>
          {isHolidaysOpen&&<Holidays onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}></Holidays>}
          <a href="/#Cruise" className="block lg:inline-block hover:text-blue-500 p-3 text-black text-xs lg:text-base"><div className='flex flex-col items-center'><img src={cruise} className='w-6 lg:w-6 '></img>
         Cruise</div></a>
          <a href="/#flights" className="block lg:inline-block hover:text-blue-500 p-3 text-black text-xs lg:text-base"><div className='flex flex-col items-center'><img src={flight} className='w-6 m-1 lg:w-7 '></img>
          Flights</div></a>
          <a href="/#hotels" className="block lg:inline-block hover:text-blue-500 p-3 text-black text-xs lg:text-base"> <div className='flex flex-col items-center'><img src={hotel} className='w-6 lg:w-6'></img>
         Hotels</div></a>
         <a href="/#Visa" className="block lg:inline-block hover:text-blue-500 p-3 text-black text-xs lg:text-base"> <div className='flex flex-col items-center'><img src={visa} className='w-6 lg:w-6 '></img>
        Visa</div></a>
        <a href="/#contactUs" className="block lg:inline-block hover:text-blue-500 p-3 text-black text-xs lg:text-base">  <div className='flex flex-col items-center'><img src={contact} className='w-5 lg:w-6 '></img>
          Contact Us</div></a>

        </div>
        
        
      </div>
      <div className={`w-full lg:flex lg:items-center lg:justify-end ${isMenuOpen ? 'block' : 'hidden'} lg:w-auto px-2 py-2 lg:rounded`}>
        <div className="relative mr-3">
          <div className="inline-flex flex-col justify-center relative text-gray-500">
            <div className="relative ">
              <input type="text" className="w-80 lg:w-36 lg:h-7  p-2 pl-8 mt-4 rounded border border-gray-200 bg-gray-200 bg-white outline-none ring-2 ring-black-600 focus:ring-yellow-600 border-transparent" placeholder="Search..." />
              <svg className="w-4 h-4 absolute left-2.5 top-[1.5rem]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <ul className="absolute top-full left-0 bg-white w-full mt-2 z-10 hidden">
              <li className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900">
                <svg className="absolute w-4 h-4 left-2 top-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                <b>Gar</b>dameer - Italië
              </li>
            </ul>
          </div>
        </div>
        <a href="#">
          <div className="flex hidden lg:block">
            <img className="w-10 p-1 ml-6 -mt-4 "src={flag}></img>
            <a href="/login">
            <div className="flex items-center h-7 w-24 rounded-md bg-[#294ccc] text-white text-xs font-medium p-2">
              Login/SignUp
            </div></a>
          </div>
        </a>
      </div>
    </nav></>
  );
}
