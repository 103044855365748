import React from 'react';
import { Helmet } from 'react-helmet';  // Import Helmet
import pondi from '../images/pondilog.avif';
import kashmir from '../images/kashmir.jpg';
import Cards from './cards';
import wing from '../images/wings.png';
import wingr from '../images/wingr.png';

export default function Section(props) {
  return (
    <section className={`${props.background} py-10`} id={`${props.name}`}>
      <Helmet>
        <title>{`${props.name} | Wings in Air`}</title>  {/* Dynamic title */}
        <meta name="description" content={`Explore exciting holidays and tours in ${props.name}.`} />  {/* Dynamic description */}
        <meta property="og:title" content={`${props.name} | Wings in Air`} />
        <meta property="og:description" content={`Explore exciting holidays and tours in ${props.name}.`} />
        <meta property="og:image" content={pondi} />  {/* Example image, replace with dynamic value if needed */}
        <meta property="og:url" content={`https://www.wingsinair.com/#${props.name}`} />
      </Helmet>

      <div className="container mx-auto ">
        <div className='flex items-center justify-center'>
          <img src={wing} alt="Left Wing" className='w-36' />
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            {props.name}
          </h2>
          <img src={wingr} alt="Right Wing" className='w-36' />
        </div>

        <div className={`grid grid-cols-1 md:grid-cols-3`}>
          {props.photos && props.photos.map((photo, index) => (
            <Cards
              key={index}
              photo={photo.value}
              name={photo.name}
              desc={photo.desc}
              price={photo.price}
              tag={photo.tag}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
