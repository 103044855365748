import React from 'react'

function CityTitle() {
  return (
    <div class="tour container pt-12 mt-10">
         <div class="tour-head">
            <div class="tour-head-left">
               <div class="tour-title">
                  Best of Turkey: Istanbul, Fethiye, Cappadocia
               </div>
               <div class="tour-overview">
                  <div class="tour-overview-item">
                     Start and end in <span>Istanbul</span>
                  </div>
                  <div class="tour-overview-item"><span>9</span> days</div>
                  <div class="tour-overview-item">
                     <span class="material-icons-outlined"> star </span>
                     <span>4.7</span> (55 reviews)
                  </div>
               </div>
            </div>
         </div>
      </div>  
  )
}

export default CityTitle