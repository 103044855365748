import React from 'react';
import { Helmet } from 'react-helmet';

export default function Testimonies() {
  return (
    <>
      <Helmet>
        <title>Customer Testimonials | Wings in Air</title>
        <meta name="description" content="Read what our customers have to say about their experiences with Wings in Air travel services. From Dubai tours to cruise packages, we strive to make every journey memorable." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section id="testimonies" className="py-20 bg-slate-900">
        <div className="max-w-6xl mx-8 md:mx-10 lg:mx-20 xl:mx-auto">
          <div className="transition duration-500 ease-in-out transform scale-100 translate-x-0 translate-y-0 opacity-100">
            <div className="mb-12 space-y-5 md:mb-16 md:text-center">
              <h1 className="mb-5 text-3xl font-semibold text-white md:text-center md:text-5xl">
                It's not just us.
              </h1>
              <p className="text-xl text-gray-100 md:text-center md:text-2xl">
                Here's what others have to say about us.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
            <ul className="space-y-8">
              {/* Testimonials List */}
              <li className="text-sm leading-6">
                <div className="relative group">
                  <div className="absolute transition rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>
                  <a href="" className="cursor-pointer">
                    <div className="relative p-6 space-y-6 leading-none rounded-lg bg-slate-800 ring-1 ring-gray-900/5">
                      <div className="flex items-center space-x-4">
                        <div>
                          <h3 className="text-lg font-semibold text-white">
                            Devang A Joshi
                          </h3>
                          <p className="text-gray-500 text-md">Dubai Tour</p>
                        </div>
                      </div>
                      <p className="leading-normal text-gray-300 text-md">
                        Sister many Thanks for all ur support, service and co-operation...
                      </p>
                    </div>
                  </a>
                </div>
              </li>
              {/* Add more testimonials similarly */}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
