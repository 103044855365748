import React from 'react'
import Navbar from './navbar'
import CityTitle from './city_title'
import Slidingimg from './slidingimg'
import Itinery from './itinery'
import Contact from './contact'
import Mail from './mail'
const City = () => {
  return (
    <div>
      <Navbar></Navbar>
      <CityTitle></CityTitle>  
      <div className='-mt-20 lg:-mt-16  lg:ml-48 lg:mr-48 '>
      <Slidingimg></Slidingimg>
      </div> 
      <div className='m-24 p-6 bg-cyan-50'>
      <Itinery></Itinery>
      </div>
      <Contact></Contact>
      <Mail></Mail>
      
    </div>
  )
}

export default City